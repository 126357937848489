<template>
  <div @click="$emit('add')">
    <v-card height="260" width="200" class="ma-2 add-partner-btn rounded-lg" elevation="12" color="grey">
      <v-row class="fill-height w-100 d-flex justify-center align-center ma-0">
        <div class="rounded-circle" style="border: 3px solid dimgrey">
          <v-icon large>{{ plusIcon }}</v-icon>
        </div>
      </v-row>
    </v-card>
  </div>
</template>
<script>
export default {
  name: 'AddPartnerBtn',
}
</script>
<style lang="scss" scoped>
.add-partner-btn:hover {
  cursor: pointer;
}
</style>