<template>
  <v-container class="pa-14">
    <div v-bind:class="{'fade': this.show || this.form}" >
      <v-row class="pb-5 d-flex flex-column">
        <v-col cols="12" class="mb-4 w-100">
          <h1 class="display-3 font-weight-bold mb-3 text-grey text-center">
            {{ $t("ourPartners") }}
          </h1>
        </v-col>
        <v-progress-linear
          v-show="loading"
          indeterminate
          :color="this.primaryColor"
        ></v-progress-linear>
        <v-col cols="12">
          <v-row class="d-flex flex-wrap justify-center">
            <Partner 
            v-for="(partner, index) in this.getPartners" :key="index" :partner="partner"
            @show="openModal({ partner: partner, type: 'show' })" 
            @edit="openModal({ partner: partner, type: 'form', formType: 'edit' })"
            />
            <AddPartnerBtn @add="openModal( { type: 'form', formType: 'add' })"/>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <div>
      <ShowPartnerModal 
        @close="closeForm()" 
        class="modal" 
        :show="show"
        :name="this.modalName" 
        :description="this.modalDescription" 
        :avatar="this.modalAvatar" 
        :id="this.modalId"/>
    </div>

    <div v-if="form">
      <FormPartnerModal 
        :form="form"
        @add="newPartner" 
        @edit="editPartner" 
        @delete="removePartner" 
        @close="closeForm()" 
        :formType="this.formType"
        :name="this.modalName" 
        :description="this.modalDescription" 
        :avatar="this.modalAvatar" 
        :id="this.modalId"/>
    </div>

  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Partner from './Components/Partner'
import AddPartnerBtn from './Components/AddPartnerBtn'
import ShowPartnerModal from './Components/ShowPartnerModal'
import FormPartnerModal from './Components/FormPartnerModal'

export default {
  name: 'Partners',

  components: {
    Partner,
    AddPartnerBtn,
    ShowPartnerModal,
    FormPartnerModal,
  },

  created () {
    this.loading = true
    this.fetchPartners().then(() => {
      this.loading = false
    });
  },

  data () {
    return {
      loading: false,
      show: false, // to toggle the show partner modal
      form: false, // to toggle the form (edit/add partner) modal
      modalName: '', // to use as prop in edit or add modal
      modalDescription: '', // to use as prop in edit or add modal
      modalAvatar: null, // to use as prop in edit or add modal
      modalId: '', // to use as prop in edit or add modal
      formType: '' // to use as prop in edit or add modal to determine whether it is edit or add new partner
    }
  },
  
  computed: mapGetters("Partners", ["getPartners"]),

  methods: {
    ...mapActions("Partners", ["fetchPartners", "addPartner", "updatePartner", "deletePartner"]),

    /**
     * @author Ibrahim Zanbily
     * @task BACT-198-test2
     * @desc displays a modal to show a partner when clicked or edit a partner depending on form type
     */
    openModal (modal) {
      if (modal.partner){
        this.modalName = modal.partner.name;
        this.modalDescription = modal.partner.description;
        this.modalAvatar = modal.partner.avatar;
        this.modalId = modal.partner.id;
      }
      if (modal.type == 'show') {
        this.show = true;
      } else if (modal.type == 'form') {
        this.form = true,
        this.formType = modal.formType
      }
    },

    editPartner (payload) {
      console.log(payload);
      this.updatePartner(payload);
      this.closeForm();
    },

    newPartner (payload) {
      console.log(payload);
      this.addPartner(payload);
      this.closeForm();
    },

    removePartner (payload) {
      console.log(payload);
      this.deletePartner(payload.id);
      this.closeForm();
    },
    closeForm () {
      this.modalName = '';
      this.modalDescription = '';
      this.modalAvatar = null;
      this.modalId = '';
      this.form = false;
      this.show = false;
      this.formType = '';
    },
  },
}
</script>
<style lang="scss" scoped>

.modal {
  height: 88vh;
  z-index: 1;
  overflow-x: hidden;
  overflow-y: scroll;
  animation: enterModal 0.3s ease-in-out;
}
@media (max-width: 400px) {
  .modal {
    left: 1%;
  }
}
.fade {
  opacity: 0.5;
}
@keyframes enterModal {
  from {
    top: -60%;
  }
  to {
    top: 8%;
  }
}
</style>