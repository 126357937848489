<template>
  <v-dialog persistent max-width="500px" v-model="form">
    <v-card min-height="500px" class="pa-4 rounded-lg" elevation="12">
      <v-form ref="form">
        <v-hover v-slot="{hover}">
          <v-icon @click="close()" x-large :color="hover ? 'black' : ''">{{ closeIcon }}</v-icon>
        </v-hover>
        
        <v-row class="d-flex justify-center my-4">
          <v-img
            :src="getImg"
            max-width="300px"
            max-height="200px"
            :alt="avatar"
          ></v-img>
        </v-row>

        <v-row>
          <v-subheader>{{ this.$t('partners.avatar') }}</v-subheader>
          <v-file-input
            accept="image/*"
            :prepend-icon="cameraIcon"
            v-model="newAvatar"
            :rules="formType==='add' ? imageRules : noRules"
          ></v-file-input> 
        </v-row>

        <v-row>
          <v-subheader>{{ this.$t('partners.name') }}</v-subheader>
          <v-text-field 
          class="main-color text-lg pa-1" 
          text-center v-model="newName"
          :rules="nameRules"
          ></v-text-field>
        </v-row>

        <v-row>
          <v-subheader>{{ this.$t('partners.description') }}</v-subheader>
        </v-row>

          <v-textarea 
          class="main-color text-lg pa-1" 
          v-model="newDescription"
          :rules="descriptionRules"
          auto-grow
          ></v-textarea>

        <v-row justify="space-around">
        <div class="d-flex justify-center">
          <base-button
            class="ma-2"
            @click="save()"
            :options="{ isLoading: false }"
          >
            {{ $t("form.save") }}
          </base-button>
          <base-button
            @click="close()"
            class="ma-2"
            :options="{ color: this.YColor }"
          >
            {{ $t("form.cancel") }}
          </base-button>
        </div>
        <base-button
          v-if="this.formType == 'edit'"
          @click="deleteConfirm = newId"
          class="ma-2"
          :options="{ color: this.RColor }"
          >
            {{ $t("partners.deletePartner") }}
          </base-button>
        </v-row>
      </v-form>
      <confirm-delete
        v-if="deleteConfirm >= 0"
        v-bind="{ id: deleteConfirm, isOpen: true }"
        @handleClose="deleteConfirm = -1"
        @handelRequest="$emit('delete', {id: id})"
      ></confirm-delete>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseButton from "@/core/Base/Buttons/BaseButton";
import ConfirmDelete from "../../../Components/confirmDelete";

export default {
  name: 'FormPartnerModal',
  components: { BaseButton, ConfirmDelete },
  props: [
    'form',
    'formType',
    'name',
    'description',
    'avatar',
    'id', // the current index to use in the edit case
  ],
  data () {
    return {
      nameRules: [(v) => !!v || this.$t('required.field')],
      descriptionRules: [(v) => !!v || this.$t('required.text')],
      imageRules: [(v) => !!v || this.$t('required.field')],
      noRules: [],
      deleteConfirm: -1,
      newName: this.name,
      newDescription: this.description,
      newAvatar: null,
      newId: this.id
    }
  },
  methods: {
    save () {
      if (!this.$refs.form.validate()) return;
      if (this.formType == 'edit') {
        this.$emit('edit', { 
          name: this.newName, 
          description: this.newDescription, 
          avatar: this.newAvatar, 
          id: this.newId 
        })
      } else if (this.formType == 'add') {
        this.$emit('add', { 
          name: this.newName, 
          description: this.newDescription, 
          avatar: this.newAvatar, 
        })
      }
    },
    close () {
      this.$refs.form.resetValidation(); 
      this.$emit('close');
    }
  },
  computed: {
    getImg() {
      if (this.newAvatar) {
        return URL.createObjectURL(this.newAvatar)
      } else {
        return `https://bact-back.usol.ca/${this.avatar}`;
      }
    },
  },
}
</script>